import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import { View, ActivityIndicator } from 'react-native'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Image, Page, Space16, StoreProvider, Title24Bold, Title28Italic, getData, getDeviceTimezone, initAnalytics, metadata, s, store, storeData, useInit } from './Util.web'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { Post } from './Post'
import { Home } from './Home'
import { Terms } from './Terms'
import { Privacy } from './Privacy'
import { Prepare } from './Prepare'
import { User } from './User'
import { Sent } from './Sent'
initAnalytics()

const router = createBrowserRouter([
  {
    children: [
      { path: '/', element: <Home /> },
      { path: '/post/:id', element: <Post /> },
      { path: '/user/:id/:gameId', element: <User /> },
      { path: '/sent', element: <Sent /> },
      { path: '/prepare', element: <Prepare /> },
      { path: '/terms', element: <Terms /> },
      { path: '/privacy', element: <Privacy /> }
    ],
    errorElement: <NotFound />
  }
])

function Root() {
  let [loaded, setLoaded] = useState()

  useInit(async () => {
    // Web Metadata
    // metadata('Post Yours', 'Post Yours', 'Interactive prompts for friends!', prodUrl)

    // Load Resources
    if (!/bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent)) {
      let fonts = ['Regular', 'Medium', 'Semibold', 'Bold', 'Black', 'Super'].map((d, i) => {
        let font = new FontFace(`Graphik-${d}`, `url('/static/graphik/Graphik${d}.woff2')`)
        document.fonts.add(font)
        return font.load()
      })
      await Promise.all(fonts)
    }

    // Ready
    setLoaded(true)
  })

  if (!loaded) return <Loading />
  return <RouterProvider router={router} fallbackElement={<Loading />} />
}

function Loading() {
  return (
    <Page>
      <View style={{ ...s.center, ...s.flex }}>
        <ActivityIndicator size='large' color='white' />
      </View>
    </Page>
  )
}
function NotFound() {
  return (
    <Page>
      <View style={{ ...s.center, ...s.flex }}>
        <Image url={require('./assets/logo.png')} style={{ width: '100%', height: 80 }} />
        <Space16 />
        <Title24Bold>Not Found</Title24Bold>
      </View>
    </Page>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <StoreProvider>
    <ActionSheetProvider>
      <Root />
    </ActionSheetProvider>
  </StoreProvider>
)
