import React, { useState } from 'react'
import { Alert, View } from 'react-native'
import { Button, Button1, Title18Bold, LinearGradient, Image, Page, useInit, Space12, Space16, Space24, Space32, Space40, Space8, SpaceGrow, Title20Bold, TitleSmall, getData, init, navigation, openLink, s, Title28Bold, Title24Bold, Title12, Title20SemiBold, storeData, navigationReset, Title16Regular, Space4, metadata, firestore, Space20, Space10, request, getDeviceId } from './Util.web'
import { appleStore, download, privacyUrl, selectFile, server, termsUrl, uploadImage, useLogin } from './API'
import { useNavigate, useSearchParams } from 'react-router-dom'

export function Post(props) {
  let { post, game, snapchatData } = window.ssrData
  if (!post) throw 'error'
  const navigate = useNavigate()

  let distinct_id = post.uid || post.deviceId || null

  useInit(async () => {
    track('web_pageview', {
      page: 'post',
      postId: post.id,
      post_source: post.uid != null ? 'app' : 'web',
      snapchatName: snapchatData?.name,
      snapchatAvatar: snapchatData?.avatar
    })

    if (distinct_id) {
      track('web_received_pageview', {
        distinct_id,
        page: 'post',
        postId: post.id,
        post_source: post.uid != null ? 'app' : 'web'
      })
    }

    if (post.uid) {
      request('post', '/api/seen', { post })
    }
  })

  const next = async () => {
    try {
      track('web_post_tapped_create', {
        postId: post.id,
        gameId: game.id,
        post_source: post.uid != null ? 'app' : 'web'
      })

      if (distinct_id) {
        track('web_received_post_tapped_create', {
          distinct_id,
          postId: post.id,
          gameId: game.id,
          post_source: post.uid != null ? 'app' : 'web'
        })
      }

      window?.snaptr('track', 'SIGN_UP')
      let imageData = await selectFile()
      if (!imageData) return
      navigate('/prepare', { state: { imageData, game, post } })
    } catch (e) {
      print('Error', e)
      alert('An error occured uploading your image!')
    }
  }

  return (
    <Page>
      <Image url={post?.backgroundImage} resizeMode={'cover'} style={{ ...s.absolute, left: 0, zIndex: 0, height: '100%', top: 0 }} />
      <LinearGradient colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0.9)']} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }} style={{ ...s.absolute, left: 0, zIndex: 0, height: 300, bottom: 0 }} />

      <Space20 />
      <View style={[s.row, s.gutter16]}>
        <Button
          onPress={() => {
            navigate('/')
          }}>
          <Image url={require('./assets/logo.png')} style={{ width: 60, height: 40 }} />
        </Button>
        <SpaceGrow />
        <Button
          style={{ ...s.row, backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: 100, ...s.center, height: 36, paddingHorizontal: 16 }}
          onPress={() => {
            if (distinct_id) {
              track('web_received_tapped_download', { distinct_id }, () => {
                download()
              })
            } else {
              download()
            }
          }}>
          <Image url={require('./assets/plus.png')} style={{ width: 8, height: 8 }} resizeMode={'contain'} />
          <Space10 />
          <Title16Regular style={{ fontFamily: 'Graphik-Medium' }}>create new</Title16Regular>
        </Button>
      </View>
      <SpaceGrow />

      <View style={{ ...s.gutter40 }}>
        <View style={{ ...s.gutter24 }}>
          <Title20Bold style={{ ...s.textCenter, fontSize: 22 }}>{game?.title}</Title20Bold>
        </View>
        <Space24 />
        <Button1 text={'Post Yours!'} style={{ width: '100%' }} icon={require('./assets/camera.png')} loading={true} animate={true} onPress={next} />
      </View>

      <View style={{ height: 52 }} />
    </Page>
  )
}
