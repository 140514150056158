import React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { View, Text, Keyboard, TouchableOpacity, Image as Image_, Dimensions, ActivityIndicator, TextInput as TextInputNative } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import mixpanel from 'mixpanel-browser'
export { useActionSheet } from '@expo/react-native-action-sheet'

// Uncompatible/Unnecessary Web Packages
export const { RNReactNativeHapticFeedback, Clipboard, FFmpegKit, FFmpegKitConfig, ReturnCode, Share, RNFS, ReactNativeInAppReview } = {}

// Actionsheet
// const { showActionSheetWithOptions } = useActionSheet();

// Production
export const production = process.env.NODE_ENV === 'production'

// Page
export function Page(props) {
  return (
    <View id='app' {...props} style={[s.center, props.style]}>
      <View style={{ maxWidth: 600, width: '100%', maxHeight: 900, height: '100%', position: 'static', ...(props.innerStyle || {}) }}>{props.children}</View>
      {/* {props.children} */}
    </View>
  )
}

export const screenWidth = Dimensions.get('window').width
export const screenHeight = Dimensions.get('window').height

// Open Link
export function openLink(url) {
  window.open(url, '_blank')
}

// Space
export function Space2(props) {
  const size = 2
  return <View style={{ width: size, height: size }} />
}
export function Space4(props) {
  const size = 4
  return <View style={{ width: size, height: size }} />
}
export function Space6(props) {
  const size = 6
  return <View style={{ width: size, height: size }} />
}
export function Space8(props) {
  const size = 8
  return <View style={{ width: size, height: size }} />
}
export function Space10(props) {
  const size = 10
  return <View style={{ width: size, height: size }} />
}
export function Space12(props) {
  const size = 12
  return <View style={{ width: size, height: size }} />
}
export function Space16(props) {
  const size = 16
  return <View style={{ width: size, height: size }} />
}
export function Space20(props) {
  const size = 20
  return <View style={{ width: size, height: size }} />
}
export function Space24(props) {
  const size = 24
  return <View style={{ width: size, height: size }} />
}
export function Space32(props) {
  const size = 32
  return <View style={{ width: size, height: size }} />
}
export function Space40(props) {
  const size = 40
  return <View style={{ width: size, height: size }} />
}
export function SpaceGrow(props) {
  return <View style={{ flex: 1 }} />
}

// Titles
export function Title36Black(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Black', fontSize: 36, color: 'white' }, props.style]} />
}
export function Title28Italic(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-BoldItalic', fontSize: 28, color: 'white' }, props.style]} />
}
export function Title28Bold(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Bold', fontSize: 28, lineHeight: 32, color: 'white' }, props.style]} />
}
export function Title24Bold(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Semibold', fontSize: 24, lineHeight: 34, color: 'white' }, props.style]} />
}
export function Title22(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Bold', fontSize: 22, lineHeight: 28, color: 'white' }, props.style]} />
}
export function Title20Bold(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Bold', fontSize: 20, lineHeight: 28, color: 'white' }, props.style]} />
}
export function Title20SemiBold(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-SemiBold', fontSize: 20, lineHeight: 28, color: 'white' }, props.style]} />
}
export function Title18Bold(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Bold', fontSize: 18, color: 'white' }, props.style]} />
}
export function Title18(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Regular', fontSize: 18, lineHeight: 24, color: 'white' }, props.style]} />
}
export function Title17(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Regular', fontSize: 17, lineHeight: 24, color: 'white' }, props.style]} />
}
export function Title16Regular(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Regular', fontSize: 16, lineHeight: 22, color: 'white' }, props.style]} />
}
export function Title14Bold(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Semibold', fontSize: 14, color: 'white' }, props.style]} />
}
export function Title14Regular(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Regular', fontSize: 14, lineHeight: 16, color: 'white' }, props.style]} />
}
export function TitleSmall(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Regular', fontSize: 12, lineHeight: 14, color: 'white' }, props.style]} />
}
export function Title12(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Regular', fontSize: 12, lineHeight: 14, color: 'white' }, props.style]} />
}
export function Title11Bold(props) {
  return <Text {...props} style={[{ fontFamily: 'Graphik-Bold', fontSize: 11, lineHeight: 14, color: 'white' }, props.style]} />
}

// Animations
export function AnimationScaleUp(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'scaleUp 0.4s linear' }, style]} />
}

export function AnimationFadeInOut(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'fadeInOut 0.4s' }, style]} />
}

export function AnimationFadeIn(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'fadeIn 0.4s' }, style]} />
}

export function AnimationRotate(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'rotate 3.6s infinite linear' }, style]} />
}

export function AnimationPulse(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'pulse 2.2s infinite linear' }, style]} />
}

export function AnimationGems(props) {
  return <View {...props} />
}

export function AnimationShake(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'shake 2.2s infinite linear' }, style]} />
}

const shadow = (y = 0, radius = 8, color = 'black', opacity = 1) => ({
  backgroundColor: 'black',
  shadowOffset: { width: 0, height: y },
  shadowRadius: radius,
  shadowColor: color,
  shadowOpacity: opacity
})

const textShadow = (y = 0, radius = 8, color = 'black') => ({
  textShadowColor: color,
  textShadowOffset: { width: 0, height: y },
  textShadowRadius: radius
})

export const s = {
  // theme2: '#DC00F5',
  theme1: 'hsla(216, 100%, 64%, 1)',
  theme2: 'hsla(216, 100%, 64%, 1)',
  theme3: 'hsla(216, 100%, 44%, 1)',
  // theme1: '#000',
  // theme2: '#313',
  auto: { alignSelf: 'center', width: 'auto' },
  gutter8: { paddingHorizontal: 8 },
  gutter16: { paddingHorizontal: 16 },
  gutter20: { paddingHorizontal: 20 },
  gutter24: { paddingHorizontal: 24 },
  gutter32: { paddingHorizontal: 32 },
  gutter40: { paddingHorizontal: 40 },
  icon: { width: 24, height: 24 },
  iconLarge: { width: 36, height: 36 },
  row: { flexDirection: 'row' },
  wrap: { flexDirection: 'row', flexWrap: 'wrap' },
  center: { justifyContent: 'center', alignItems: 'center' },
  spread: { justifyContent: 'space-between' },
  absolute: { position: 'absolute', width: '100%', zIndex: 1 },
  flex: { flex: 1 },
  red: { backgroundColor: 'red' },
  blue: { backgroundColor: 'blue' },
  blackText: { color: 'black' },
  textCenter: { textAlign: 'center' },
  shadow: shadow,
  textShadow: textShadow,
  shadow1: shadow(2, 1, 'rgba(0,0,0,0.6)'),
  shadow2: shadow(16, 32, 'rgba(0,0,0,0.2)'),
  textShadow1: textShadow(2, 1, 'rgba(0,0,0,0.4)')
}

export const Image = props => {
  let { url, resizeMode = 'contain' } = props
  let source = url
  if (typeof url === 'string') source = { uri: url }

  return <Image_ resizeMode={resizeMode} {...props} source={source} />
}
export const preload = async urls => {
  if (!Array.isArray(urls)) urls = [urls]
  return await Promise.all(urls.map(d => Image_.prefetch(d)))
}
export async function imageToBlob(url) {
  const response = await fetch(url)
  return await response.blob()
}
export async function request(method, url, data = '', headers = {}) {
  try {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 3000)
    let r = await fetch(url, {
      method: method.toUpperCase(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers
      },
      body: typeof data === 'object' && data.constructor.name !== 'Blob' ? JSON.stringify(data) : data != null ? data : undefined,
      signal: controller.signal
    })

    r = await r.text()
    try {
      return JSON.parse(r)
    } catch (e) {
      return r
    }
  } catch (e) {
    console.log('Error with', data, e)
    return { error: 'An error occured' }
  }
}

export function LinearGradient(props) {
  let { colors, style } = props
  return <View {...props} style={[{ background: `linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 100%)` }, style]} />
}

// Array
export const createArray = num => {
  return [...Array(num).keys()]
}

// Blah
export function vibrate(level = 1) {
  let p = 'impactLight'
  if (level === 2) p = 'impactHeavy'
  RNReactNativeHapticFeedback?.trigger(p, { enableVibrateFallback: true })
}

// Init
export const useInit = (f, changeOn = []) => {
  useEffect(() => {
    f()
  }, changeOn)
}

// Sleep
export function sleep(mili = 0) {
  return new Promise(done => {
    setTimeout(done, mili)
  })
}

// Store
const StoreContext = createContext()
export const store = {}
export const useStore = () => useContext(StoreContext)
export function StoreProvider(props) {
  const [state, setState] = useState(store)
  const setStore = data => {
    Object.assign(store, data)
    setState({ ...store })
  }

  // Keyboard up and down
  useEffect(() => {
    let s1 = Keyboard.addListener('keyboardWillShow', () => {
      setStore({ keyboardUp: Math.random() })
    })
    let s2 = Keyboard.addListener('keyboardWillHide', () => setStore({ keyboardUp: 0 }))
    return () => {
      s1.remove()
      s2.remove()
    }
  })

  return <StoreContext.Provider value={[state, setStore]}>{props.children}</StoreContext.Provider>
}

// Storage
export const getData = async key => {
  try {
    return JSON.parse(await AsyncStorage.getItem(key))
  } catch (e) {
    return null
  }
}
export const storeData = async data => {
  try {
    for (let k in data) {
      await AsyncStorage.setItem(k, JSON.stringify(data[k]))
    }
  } catch (e) {
    return null
  }
}
export const clearData = async () => {
  try {
    return await AsyncStorage.clear()
  } catch (e) {
    return null
  }
}

// TextInput
export const TextInput = props => {
  return <TextInputNative placeholderTextColor='rgba(0,0,0,0.4)' keyboardAppearance={'dark'} {...props} style={[{ ...s.shadow(8, 16, 'black', 0.1), width: '100%', height: 56, backgroundColor: 'rgba(255,255,255,1)', fontFamily: 'Graphik-Medium', fontSize: 21, color: '#334', textAlign: 'center', borderRadius: 100 }, props.style]} />
}
export const ParagraphInput = props => {
  return <TextInputNative multiline={true} placeholderTextColor='rgba(255,255,255,0.4)' keyboardAppearance={'light'} {...props} style={[{ width: '100%', fontFamily: 'Graphik-Medium', fontSize: 19, color: '#fff' }, props.style]} />
}

// Navigation
export const navigation = {}

// Button
export function Button(props) {
  let { loading } = props
  let _vibrate = props.vibrate === undefined ? 'normal' : props.vibrate

  let [lloading, setLoading] = useState(false)

  return (
    <TouchableOpacity
      style={{ ...s.auto, pointer: 'cursor' }}
      pointerEvent={'none'}
      hitSlop={8}
      {...props}
      onPress={async () => {
        if (_vibrate === 'normal') {
          vibrate()
        } else if (_vibrate === 'hard') {
          vibrate(2)
          setTimeout(() => vibrate(2), 75)
        }
        if (loading) setLoading(true)
        await props.onPress?.()
        if (loading) setLoading(false)
      }}>
      {props.children}
      {lloading && <ActivityIndicator color={'#666'} style={{ position: 'absolute', backgroundColor: '#fff', left: 10, right: 10, top: 10, bottom: 10, borderRadius: 100 }} />}
    </TouchableOpacity>
  )
}

export const getFlagEmoji = countryCode => {
  if (!countryCode) return ''
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt())
  return String.fromCodePoint(...codePoints)
}

export function useSafeAreaInsets() {
  return {
    bottom: 0,
    top: 0
  }
}

export function Dashes(props) {
  let { total, index } = props

  let width = `${100 / total - 2}%`
  return (
    <View style={{ ...s.row, ...s.spread }}>
      {[...Array(total).keys()].map(d => (
        <View style={[{ width: width, height: 4, borderRadius: 100, backgroundColor: index === d ? 'white' : 'rgba(255,255,255,0.3)' }]} key={d} />
      ))}
    </View>
  )
}

export const getDeviceTimezone = () => {
  return parseInt(-new Date().getTimezoneOffset() / 60)
}

export function userRedacted() {
  let { _object, id, name, region, pfp, country, age } = store.user
  return { _object, id, name, region, pfp, country, age }
}

// ActionSheet
export function Options({ _ref, children, destructive }) {
  // return (
  //   <ActionSheet
  //     ref={_ref}
  //     useCustomActionSheet={true}
  //     options={['Cancel', ...children.map(d => d[0])]}
  //     cancelButtonIndex={0}
  //     destructiveButtonIndex={destructive && children.length}
  //     tintColor={'#3347FF'}
  //     onPress={async index => {
  //       if (index === 0) return
  //       children[index - 1][1]()
  //     }}
  //   />
  // )
}

export function Button1(props) {
  let { icon, text, disabled = false, animate = true, buttonStyle = {}, tintColor = s.theme3, Animation = AnimationShake } = props

  return (
    <Button {...props}>
      <Animation animate={animate} style={disabled && { opacity: 1 }}>
        <View style={{ ...s.shadow2, ...s.row, ...s.center, paddingHorizontal: 24, height: 56, backgroundColor: '#fff', borderRadius: 100, ...buttonStyle }}>
          {icon && (
            <>
              <Image url={icon} style={{ width: 22, height: 22 }} resizeMode={'contain'} />
              <Space10 />
            </>
          )}
          <Title20Bold style={[{ color: 'black', marginTop: 1, fontFamily: 'Graphik-Super', fontSize: 19 }, props.textStyle]}>{text}</Title20Bold>
        </View>
      </Animation>
    </Button>
  )
}

export function metadata(name, title, description, url) {
  let language = 'en'

  document.documentElement.lang = language
  document.querySelector('meta[property="og:site_name"]').content = name

  // document.querySelector('link[rel="canonical"]').href = host + window.location.pathname
  // document.querySelector('meta[property="og:url"]').content = host + window.location.pathname

  document.querySelector('title').textContent = title
  document.querySelector('meta[property="og:title"]').content = title
  document.querySelector('meta[name="twitter:title"]').content = title

  document.querySelector('meta[name="description"]').content = description
  document.querySelector('meta[property="og:description"]').content = description
  document.querySelector('meta[name="twitter:description"]').content = description

  document.querySelector('meta[property="og:image"]').content = `${url}/static/images/logo.jpg?v=2`
  document.querySelector('meta[name="twitter:image"]').content = `${url}/static/images/logo.jpg?v=2`
}

// MIXPANEL ANALYTICS
export function initAnalytics() {
  mixpanel.init('bedfdab02e686c4c9ed8cd46604d43e7', { debug: true, track_pageview: true, persistence: 'localStorage' })
}

window.identify = (...args) => {
  print('ANALYTICS_IDENTIFY', args[0])
  return mixpanel.identify(...args)
}
window.track = (...args) => {
  print('ANALYTICS', args[0], args[1])
  return mixpanel.track(...args)
}

export function getDeviceId() {
  return mixpanel.get_distinct_id()
}

// Firebase
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// Initialize Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyCvNk5kPmlX-rjfxFd2eFI8gIgPebOkAio',
  authDomain: 'snapyours.firebaseapp.com',
  projectId: 'snapyours',
  storageBucket: 'snapyours.appspot.com',
  messagingSenderId: '1091536270950',
  appId: '1:1091536270950:web:e46502ab64798b89d61fa7'
})

export const firestore = firebase.firestore()
export const FieldValue = firebase.firestore.FieldValue
