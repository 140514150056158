import React, { useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { Button1, FieldValue, Page, Space12, Space20, Space24, Title20Bold, Title28Bold, firestore, getDeviceId, s, useInit } from './Util.web'
import { useLocation } from 'react-router-dom'
import { uploadImage } from './API'

export function Prepare(props) {
  let { imageData, game, post } = useLocation().state || {}
  let [postId, setPostId] = useState()

  let distinct_id = post.uid || post.deviceId || null

  const upload = async () => {
    try {
      let backgroundImage = await uploadImage(imageData)

      let post = await firestore.collection('posts').add({
        timestamp: Date.now(),
        gameId: game.id,
        backgroundImage,
        deviceId: getDeviceId()
      })

      // Increment numShares
      firestore
        .collection('games')
        .doc(game.id)
        .update({
          numShares: FieldValue.increment(1)
        })

      setPostId(post.id)
    } catch (e) {
      print(e)
      setPostId('error')
      alert('An error occured uploading your image!')
    }
  }

  const next = () => {
    track(
      'web_prepare_tapped_continue',
      {
        postId: post.id
      },
      () => {
        if (distinct_id) {
          track('web_received_prepare_tapped_continue', { distinct_id, postId: post.id }, () => {
            let url = `https://snapchat.com/scan?attachmentUrl=${encodeURIComponent(`https://postyours.app/post/${postId}`)}`
            window.open(url, '_blank')
          })
        } else {
          let url = `https://snapchat.com/scan?attachmentUrl=${encodeURIComponent(`https://postyours.app/post/${postId}`)}`
          window.open(url, '_blank')
        }
      }
    )
  }

  useInit(() => {
    upload()
  })

  return (
    <Page innerStyle={{ ...s.center }}>
      {postId === 'error' ? (
        <>
          <Title28Bold>Upload error!</Title28Bold>
          <Button1 text={'Try again'} loading={false} animate={false} onPress={upload} />
        </>
      ) : postId ? (
        <>
          <Title28Bold>Post Ready!</Title28Bold>
          <Space20 />
          <Button1 text={'Continue in Snapchat'} style={{}} icon={require('./assets/share_black.png')} loading={false} animate={false} onPress={next} />
        </>
      ) : (
        <>
          <ActivityIndicator color={'#fff'} size={'large'} />
          <Space12 />
          <Title20Bold>Preparing Post</Title20Bold>
        </>
      )}
    </Page>
  )
}
