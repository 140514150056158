import React, { useState } from 'react'
import { Alert, View } from 'react-native'
import { Button, Button1, Title18Bold, LinearGradient, Image, Page, useInit, Space12, Space16, Space24, Space32, Space40, Space8, SpaceGrow, Title20Bold, TitleSmall, getData, init, navigation, openLink, s, Title28Bold, Title24Bold, Title12, Title20SemiBold, storeData, navigationReset, Title16Regular, Space4, metadata, firestore, Space20, Space10, request, getDeviceId, ParagraphInput, Title17, sleep, Title22 } from './Util.web'
import { appleStore, download, privacyUrl, selectFile, server, termsUrl, uploadImage, useLogin } from './API'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'

export function Sent(props) {
	const navigate = useNavigate()

  return (
    <Page>
      <View style={{ ...s.flex, ...s.center }}>
        <Image url={require('./assets/check.png')} style={{ width: 90, height: 90 }} />
        <Space20 />
        <Title22>Message Sent!</Title22>
        <Space40 />
        <Button1
          text={'Get your own messages'}
          style={{ width: '100%', ...s.gutter40 }}
          buttonStyle={{ backgroundColor: '#F22B16' }}
          textStyle={{ color: 'white' }}
          loading={true}
          onPress={() => {
            download()
          }}
        />
      </View>
    </Page>
  )
}
