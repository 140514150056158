import { production, getData, openLink, imageToBlob, request, sleep, store, storeData, userRedacted, Clipboard, FFmpegKit, FFmpegKitConfig, ReturnCode, Share, RNFS, ReactNativeInAppReview } from './Util.web'
import axios from 'axios'

// Baseurl
export let baseUrl = 'http://mm.local:3000'
export let prodUrl = 'https://www.postyours.app'
export let termsUrl = 'https://glacier-stool-8a0.notion.site/Terms-Conditions-bb8e40225bbe4a29a338fb3b9061301d'
export let privacyUrl = 'https://glacier-stool-8a0.notion.site/Privacy-Policy-300660f9ebb54a578ed4f10919b2fb9b'
export let appleStore = 'https://apps.apple.com/us/app/post-yours/id6478239938'
export let playStore = null
if (production) baseUrl = prodUrl

export function download() {
  window?.snaptr('track', 'PURCHASE')
  track('web_tapped_download', {}, () => {
    if (navigator.userAgent.toLowerCase().indexOf('android') >= 0) {
      alert('Hang tight! We are expecting to be the Android Play store by end of September!')
    } else {
      openLink(appleStore)
    }
  })
}

export const uploadImage = async file => {
  let uploadUrl = await axios.post('/api/upload-image')
  uploadUrl = uploadUrl.data.uploadUrl

  const bodyFormData = new FormData()
  bodyFormData.append('file', file)
  const response = await axios.post(uploadUrl, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data.result.variants[0]
}

export const selectFile = async () => {
  return new Promise(done => {
    document.getElementById('file-selector').click()
    document.getElementById('file-selector').onchange = event => {
      done(event.target.files[0])
    }
    document.getElementById('file-selector').oncancel = event => {
      done()
    }
  })
}
