import React from 'react'
import { Alert, View } from 'react-native'
import { Button, Button1, Title18Bold, LinearGradient, Image, Page, useInit, Space12, Space16, Space24, Space32, Space40, Space8, SpaceGrow, Title20Bold, TitleSmall, getData, init, navigation, openLink, s, Title28Bold, Title24Bold, Title12, Title20SemiBold, storeData, navigationReset, Title16Regular, Space4 } from './Util.web'
import { download, privacyUrl, server, termsUrl, useLogin } from './API'

export function Home(props) {
  const next = () => {
    download()
  }

  return (
    <Page style={{}}>
      <View style={{ ...s.center, ...s.flex }}>
        <Space40 />
        <Space32 />
        <Image url={require('./assets/logo.png')} style={{ width: 268, height: 168 }} />
        <Space24 />

        <Title20SemiBold>Interactive Stickers with Friends</Title20SemiBold>
        {/* <Title20SemiBold>TEST</Title20SemiBold> */}
      </View>

      <View style={{ ...s.gutter40 }}>
        <Button1 text={'download app!'} style={{ }} loading={true} onPress={next} />
        <Space24 />

        <View style={{ ...s.spread, ...s.row, ...s.gutter40 }}>
				<Button onPress={() => openLink("mailto:snapyoursapp@gmail.com")}>
            <Title12
              style={{
                fontFamily: 'Graphik-Medium',
                opacity: 0.4
              }}>
              Contact
            </Title12>
          </Button>
          <Button onPress={() => openLink(termsUrl)}>
            <Title12
              style={{
                fontFamily: 'Graphik-Medium',
                opacity: 0.4
              }}>
              Terms of Use
            </Title12>
          </Button>
          <Button onPress={() => openLink(privacyUrl)}>
            <Title12
              style={{
                fontFamily: 'Graphik-Medium',
                opacity: 0.4
              }}>
              Privacy Policy
            </Title12>
          </Button>
        </View>
      </View>

      <Space24 />
    </Page>
  )
}
