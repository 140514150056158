import React, { useState } from 'react'
import { Alert, View } from 'react-native'
import { Button, Button1, Title18Bold, LinearGradient, Image, Page, useInit, Space12, Space16, Space24, Space32, Space40, Space8, SpaceGrow, Title20Bold, TitleSmall, getData, init, navigation, openLink, s, Title28Bold, Title24Bold, Title12, Title20SemiBold, storeData, navigationReset, Title16Regular, Space4, metadata, firestore, Space20, Space10, request, getDeviceId, ParagraphInput, Title17, sleep, Space6 } from './Util.web'
import { appleStore, download, privacyUrl, selectFile, server, termsUrl, uploadImage, useLogin } from './API'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'

export function User(props) {
  let { user, game, snapchatData } = window.ssrData
  if (!user || !snapchatData?.avatar) throw 'error'
  const navigate = useNavigate()
  const [text, setText] = useState('')

  let distinct_id = user.id || null

  const next = async () => {
    window?.snaptr('track', 'SIGN_UP')

    if (user.blocks?.includes(getDeviceId())) {
      await sleep(1000)
      print('blocked')
      navigate('/sent')
      return
    }

    const r = (
      await axios.post('/api/question', {
        text,
        user,
        game,
        deviceId: getDeviceId(),
        referrer: document.referrer,
        snapchatData
      })
    ).data

    navigate('/sent')
  }

  return (
    <Page>
      <Space32 />
      <View style={{ marginHorizontal: 32, borderRadius: 20, overflow: 'hidden' }}>
        <View style={{ backgroundColor: 'white', padding: 24 }}>
          <Title17 style={{ fontFamily: 'Graphik-Bold', color: 'black', width: '70%' }}>Ask me anything...</Title17>
        </View>
        <View style={{ backgroundColor: 'rgba(255,255,255,0.2)', height: 150, padding: 24 }}>
          <ParagraphInput placeholder={'Ask me a question...'} style={{ height: '100%' }} value={text} onChangeText={setText} />
          {snapchatData?.avatar && (
            <View style={{ ...s.row, ...s.center, ...s.absolute, right: 24, bottom: 12, width: 'auto' }}>
              <Image url={snapchatData?.avatar} style={{ width: 24, height: 24, borderColor: 'rgba(255,255,255,0.2)', borderWidth: 1, borderRadius: 100 }} />
              <Space6 />
              <Title16Regular style={{ fontFamily: 'Graphik-Regular', opacity: 0.6 }}>{snapchatData?.name}</Title16Regular>
            </View>
          )}
        </View>
      </View>
      <Space32 />

      {text.length > 0 && <Button1 text={'Send'} animate={false} style={{ marginHorizontal: 32 }} loading={true} onPress={next} />}
      <SpaceGrow />

      <Button1
        text={'Get your own messages'}
        animate={false}
        style={{ ...s.gutter40 }}
        buttonStyle={{ backgroundColor: '#F22B16' }}
        textStyle={{ color: 'white' }}
        loading={true}
        onPress={() => {
          download()
        }}
      />
      <View style={{ height: 52 }} />
    </Page>
  )
}
